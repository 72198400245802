import React from 'react';

const WelcomeCard = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-sm w-full lg:max-w-full lg:flex">
        <div className="border border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className="text-gray-900 font-bold text-xl mb-2">Welcome to My Portfolio</div>
            <p className="text-gray-700 text-base">
	  	01110111 01101111 01110010 01101011 00100000 01101001 01101110 00100000 01110000 01110010 01101111 01100111 01110010 01100101 01110011 01110011
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;

